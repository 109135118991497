<script>
import Layout from "@/router/layouts/main";
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import StoreService from '@/services/store'
import DatePicker from "vue2-datepicker";

export default {
  page: {
    title: "Configurações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker },
  data() {
    return {
      title: "Configurações",
      workingHours: {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {},
      },
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    load(showLoading = true) {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      StoreService.getStoreData().then(res => {
        this.workingHours = res.data.workingHours || {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {},
        };

        if (showLoading) {
          loader.hide();
        }
      }).catch(() => {
        if (showLoading) {
          loader.hide();
        }
      })
    },
    save() {
      let loader = this.$loading.show();
      const data = {
        workingHours: this.workingHours,
      }

      StoreService.updateStore(data).then(() => {
        this.$toast.open('Horário de funcionamento atualizado');
        this.load(false);

        loader.hide();
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao atualizar os dados');

        loader.hide();
      })
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-8 col-lg-10 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Horário de Funcionamento</h4>
            <p class="card-title-desc">Configure o horário de abertura da sua loja para definir o período de recebimento dos pedidos</p>

            <form>
              <div class="row mb-0">
                <div class="col-sm-2 col-lg-2">
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group mb-0">
                    <label for="bank">Abre às</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group mb-0">
                    <label for="agency">Fecha às</label>
                  </div>
                </div>
              </div>

              <!-- SEGUNDA -->
              <div class="row">
                <div class="col-sm-2 col-lg-2 align-self-end">
                  <div class="form-group">
                    <label for="bank">Segunda-feira</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.monday.openTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.monday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.monday.closeTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.monday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-2 col-lg-4">
                  <div class="form-group align-self-end mt-2">
                    <div class="custom-control custom-checkbox align-self-end">
                      <input type="checkbox" class="custom-control-input" id="mondayClosed" v-model="workingHours.monday.closed" />
                      <label class="custom-control-label" for="mondayClosed">Não abrimos</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- TERCA -->
              <div class="row">
                <div class="col-sm-2 col-lg-2 align-self-end">
                  <div class="form-group">
                    <label for="bank">Terça-feira</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.tuesday.openTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.tuesday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.tuesday.closeTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.tuesday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-4 col-lg-4">
                  <div class="form-group align-self-end mt-2">
                    <div class="custom-control custom-checkbox align-self-end">
                      <input type="checkbox" class="custom-control-input" id="tuesdayClosed" v-model="workingHours.tuesday.closed" />
                      <label class="custom-control-label" for="tuesdayClosed">Não abrimos</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- QUARTA -->
              <div class="row">
                <div class="col-sm-2 col-lg-2 align-self-end">
                  <div class="form-group">
                    <label for="bank">Quarta-feira</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.wednesday.openTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.wednesday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.wednesday.closeTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.wednesday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-4 col-lg-4">
                  <div class="form-group align-self-end mt-2">
                    <div class="custom-control custom-checkbox align-self-end">
                      <input type="checkbox" class="custom-control-input" id="wednesdayClosed" v-model="workingHours.wednesday.closed" />
                      <label class="custom-control-label" for="wednesdayClosed">Não abrimos</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- QUINTA -->
              <div class="row">
                <div class="col-sm-2 col-lg-2 align-self-end">
                  <div class="form-group">
                    <label for="bank">Quinta-feira</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.thursday.openTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.thursday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.thursday.closeTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.thursday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-4 col-lg-4">
                  <div class="form-group align-self-end mt-2">
                    <div class="custom-control custom-checkbox align-self-end">
                      <input type="checkbox" class="custom-control-input" id="thursdayClosed" v-model="workingHours.thursday.closed" />
                      <label class="custom-control-label" for="thursdayClosed">Não abrimos</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- SEXTA -->
              <div class="row">
                <div class="col-sm-2 col-lg-2 align-self-end">
                  <div class="form-group">
                    <label for="bank">Sexta-feira</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.friday.openTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.friday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.friday.closeTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.friday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-4 col-lg-4">
                  <div class="form-group align-self-end mt-2">
                    <div class="custom-control custom-checkbox align-self-end">
                      <input type="checkbox" class="custom-control-input" id="fridayClosed" v-model="workingHours.friday.closed" />
                      <label class="custom-control-label" for="fridayClosed">Não abrimos</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- SABADO -->
              <div class="row">
                <div class="col-sm-2 col-lg-2 align-self-end">
                  <div class="form-group">
                    <label for="bank">Sábado</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.saturday.openTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.saturday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.saturday.closeTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.saturday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-4 col-lg-4">
                  <div class="form-group align-self-end mt-2">
                    <div class="custom-control custom-checkbox align-self-end">
                      <input type="checkbox" class="custom-control-input" id="saturdayClosed" v-model="workingHours.saturday.closed" />
                      <label class="custom-control-label" for="saturdayClosed">Não abrimos</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- DOMINGO -->
              <div class="row">
                <div class="col-sm-2 col-lg-2 align-self-end">
                  <div class="form-group">
                    <label for="bank">Domingo</label>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.sunday.openTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.sunday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <date-picker
                      v-model="workingHours.sunday.closeTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :minute-step="5"
                      :disabled="workingHours.sunday.closed"
                    ></date-picker>
                  </div>
                </div>

                <div class="col-sm-4 col-lg-4">
                  <div class="form-group align-self-end mt-2">
                    <div class="custom-control custom-checkbox align-self-end">
                      <input type="checkbox" class="custom-control-input" id="sundayClosed" v-model="workingHours.sunday.closed" />
                      <label class="custom-control-label" for="sundayClosed">Não abrimos</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-10">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded" @click="save">Salvar Horário de Funcionamento</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
  input[type="file"] {
    display: none;
  }

  .mx-input:disabled {
    background-color: #eee !important;
  }
</style>